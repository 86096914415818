<template>
    <div
        v-cloak
        class="signup container-fluid h-100">
        <div class="d-flex justify-content-center align-items-center h-100">
            <BaseCard class="signup__card">
                <template #title>
                    <div class="d-flex justify-content-between">
                        <img
                            alt="Logo"
                            :src="logo"
                            class="signup__logo">
                        <div class="d-flex align-items-end">
                            <div class="signup__powered-by mr-2">
                                powered by
                            </div>
                            <img
                                alt="Brand"
                                :src="brand"
                                class="signup__brand">
                        </div>
                    </div>
                </template>

                <template #body>
                    <div
                        v-if="ssoDuplicateEmailError"
                        class="duplicate-email-error-container d-flex alert alert-danger
                               justify-content-center text-light my-3">
                        <Icon
                            icon="fa-info-circle"
                            size="lg"
                            class="mr-2">
                        </Icon>

                        Your email is already associated with an account.
                    </div>

                    <MultiStepForm
                        v-if="!showVerification"
                        :steps="steps"
                        :form-data="signupData"
                        class="signup__container"
                        @success="onSuccess">
                    </MultiStepForm>
                    <SignupVerificationPrompt
                        v-else
                        :email="signupData.email">
                    </SignupVerificationPrompt>
                </template>
            </BaseCard>
        </div>
    </div>
</template>

<script>
import logo from '@technologyadvice/relay-frontend/src/assets/brand-logo-refresh-white-text.svg'
import {
    BaseCard,
    Icon,
} from '@technologyadvice/relay-frontend'
import MultiStepForm from '@components/ui/MultiStepForm.vue'
import SignupVerificationPrompt from '@components/account-signup/SignupVerificationPrompt.vue'
import brand from '../assets/imgs/logo/technologyadvice-logo-light.png'

export default {
    components: {
        BaseCard,
        MultiStepForm,
        SignupVerificationPrompt,
        Icon,
    },
    data() {
        return {
            logo,
            brand,
            signupData: {
                email: null,
                first_name: null,
                last_name: null,
                password: null,
                password_confirmation: null,
                company_name: null,
                company_website: null,
                special_offers_optin: false,
                phone: null,
                job_title: null,
            },
            steps: [
                {
                    title: 'Setup Your Company',
                    routeName: 'AccountDetailsStep',
                },
                {
                    title: 'Create Your User Account',
                    routeName: 'UserLoginStep',
                },
            ],
        }
    },
    computed: {
        ssoDuplicateEmailError() {
            return !!this.$route.query?.errors?.includes('email')
        },
        showVerification() {
            return this.$route.path === '/sign-up/verify'
        },
    },
    created() {
        this.initializeFormData()

        if (this.$route.query.errors) {
            this.$_toasterDanger('There was a problem with your submission. Please review your entries and try again.')
        }
    },
    methods: {
        initializeFormData() {
            this.signupData = {
                ...this.signupData,
                ...this.getPrefilledFormData(),
            }
        },
        onSuccess() {
            this.$router.push('/sign-up/verify')
        },
        getPrefilledFormData() {
            const queryParams = this.$route.query
            const allowedParams = Object.keys(this.signupData)
            return Object.keys(queryParams)
                .filter(key => allowedParams.includes(key) && queryParams[key])
                .reduce((prefilledData, key) => {
                    prefilledData[key] = queryParams[key];
                    return prefilledData;
                }, {});
        },
    },
}
</script>

<style lang="scss" scoped>
.duplicate-email-error-container {
    background-color: #c41919;
    border-color: #c41919;
}
</style>
